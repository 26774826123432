<template>
  <div class="audio-uploader video-capture-container full-width">
    <div class="controls-container">
      <audio
          v-if="currentRecording"
          :src="currentRecording.blobUrl"
          controls="true"
          class="audio-player"
      />

      <ion-button
          v-show="!recordingInProgress && !currentRecording"
          color="primary"
          class="audio-record-button"
          @click="startRecording"
          :disabled="userAudio.src"
      >
        Record Audio
        <ion-icon :icon="mic" class="mic-icon"></ion-icon>
      </ion-button>

      <ion-button
          v-show="recordingInProgress"
          color="danger"
          @click="stopRecording"
      >
        Stop Recording
        <ion-icon :icon="stopCircleOutline" class="ion-padding"></ion-icon>
        {{  formattedDuration(currentDuration) }}
      </ion-button>

      <ion-button
          v-if="currentRecording"
          @click="deleteAudio()"
          size="block"
          color="primary"
          fill="outline"
      >
        Delete
      </ion-button>
    </div>
  </div>
</template>

<script>
import {IonButton, IonIcon} from '@ionic/vue'
import RecorderService from '../../composables/web-audio-recording-utils/RecorderService'
import {mic, playCircle, pauseCircle, stopCircleOutline} from 'ionicons/icons'
import { formattedDuration } from "@/composables/MediaDuration";

const encoders = [
        { id: 'wav', name: 'audio/wav - custom - mono' },
        { id: 'mp3', name: 'audio/mpeg - zhuker/lamejs - mono/128kbps' },
        { id: 'ogg', name: 'audio/ogg - higuma/oggjs - mono/~128kps' }
]

export default {
  name: "audioUploader",
  props: {
    inputId: {
      default: 'recorder-default',
    },
    stopMedia: {
      default: false,
    }
  },
  setup() {
    return { mic, playCircle, pauseCircle, stopCircleOutline, formattedDuration };
  },
  components: {IonButton, IonIcon},
  data() {
    return {
      encoders: encoders,
      selectedEncoder: encoders[0].id,
      hasMediaRecorder: window.MediaRecorder || false,
      enableEchoCancellation: true,
      recordingInProgress: false,
      supportedMimeTypes: [],
      currentRecording: null,
      recordings: [],
      micGainSlider: 100,
      micGain: 1.0,
      cleanupWhenFinished: true,
      addDynamicsCompressor: false,
      userAudio: {
        audioElement: null,
        src: null,
        playing: false,
        duration: 0,
        currentTime: 0,
        icon: null,
        file: null
      },
      currentDuration: 0
    }
  },
  computed: {
    getDuration() {
      let duration = Math.floor(this.userAudio.duration);
      let currentTime = Math.floor(this.userAudio.currentTime);
      let remainingTime = duration - currentTime;
      let minutes = Math.floor(remainingTime / 60);
      let seconds = remainingTime - minutes * 60;
      return `${this.str_pad_left(minutes, '0', 2)}:${this.str_pad_left(seconds, '0', 2)}`
    },
  },
  methods: {
    emitAudioFile() {
      this.emitter.emit('audioUploadAttempt', {inputId: this.inputId, audioFile: this.userAudio.file})
    },
    async setAudio(e) {

      let myBlob = e.detail.recording;
      let file = e.detail.recording;
      let url = e.detail.recording.blobUrl;
      let ctx = this;

      this.userAudio.audioElement = new Audio(url);

      if (this.userAudio.audioElement) {
        ctx.sendAudio(myBlob.blobRaw)

        this.userAudio.audioElement.addEventListener('loadeddata', () => {

          // loadeddata event used for event which is fired after playback position of media element has finished loading (so we can grab values once instantiated)
          // Set up some data for the controller
          ctx.userAudio.src = url;
          ctx.userAudio.file = file
          ctx.userAudio.playing = false;
          ctx.userAudio.duration = this.userAudio.audioElement.duration;
          ctx.userAudio.icon = playCircle;
        });

        this.userAudio.audioElement.addEventListener('timeupdate', () => {
          // loadedevent used for event which is fired after playback position of media element has finished loading (so we can grab values)
          // Set up some data for the controller
          ctx.userAudio.currentTime = this.userAudio.audioElement.currentTime
          // console.log('Media Time Updated')
        });
        this.userAudio.audioElement.addEventListener('play', () => {
          ctx.userAudio.icon = pauseCircle
        });
        this.userAudio.audioElement.addEventListener('pause', () => {
          ctx.userAudio.icon = playCircle
        })
      }
    },
    async sendAudio(rawBlob) {
      let formData = new FormData();
      let ctx = this

      /* Add the form data we need to submit */
      formData.append('file', rawBlob);

      /* Make the request to the POST /single-file URL */
      await this.$http.post(process.env.VUE_APP_BACKEND_BASE_URL + '/api/auth/audiostore',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
      ).then(function (e) {
        console.log('%c Audio File Successfully Uploaded', 'background: #222; color: #bada55');
        ctx.userAudio.file = e.data
        return ctx.emitAudioFile()
      })
          .catch(function (e) {
            console.error('Failed to Upload');
            console.error(e);
            return ctx.emitAudioFile()
          });
    },
    audioPlayHandler() {
      var audio = this.userAudio.audioElement
      return audio.paused ? audio.play() : audio.pause()
    },
    str_pad_left(string, pad, length) {
      return (new Array(length + 1).join(pad) + string).slice(-length);
    },
    async deleteAudio() {
      this.userAudio.audioElement = null;
      this.userAudio.inputSrc = null;
      this.userAudio.src = null;
      this.userAudio.playing = false;
      this.userAudio.duration = 0;
      this.userAudio.currentTime = 0;
      this.userAudio.icon = null;
      this.userAudio.file = null;
      this.userAudio.file = null
      this.currentRecording = null;
      this.currentDuration = 0;
      return this.emitAudioFile()
    },
    onNewRecording(evt) {
      this.recordings.push(evt.detail.recording)
      this.currentRecording = evt.detail.recording
      this.setAudio(evt);
      console.log(evt.detail.recording)
    },
    startRecording () {
      this.recorderSrvc.config.manualEncoderId = this.selectedEncoder
      this.recorderSrvc.startRecording()
          .then(() => {
            this.recordingInProgress = true
            this.startDurationTimer()
          })
          .catch((error) => {
            console.error('Exception while start recording: ' + error)
            alert('Exception while start recording: ' + error.message)
          })
    },
    stopRecording() {
      this.recorderSrvc.stopRecording()
      this.recordingInProgress = false
    },
    startDurationTimer() {
      let wait = ms => new Promise(resolve => setTimeout(resolve, ms));
      (async () => {
        while (this.recordingInProgress) {
          await wait(1000);
          this.currentDuration += 1
        }
      })().catch(e => console.log(e));
    }
  },
  beforeUnmount() {
    return this.deleteAudio()
  },
  watch: {
    stopMedia: function () {
      // Watch prop from parent when pressing next section to stop
      if (this.userAudio.audioElement) {
        this.userAudio.audioElement.load()
      }
    },
    cleanupWhenFinished() {
      this.recorderSrvc.config.stopTracksAndCloseCtxWhenFinished = this.cleanupWhenFinished
    },
    micGainSlider() {
      this.micGain = (this.micGainSlider * 0.01).toFixed(2)
      this.recorderSrvc.setMicGain(this.micGain)
    },
  },
  created() {
    this.recorderSrvc = new RecorderService()
    // Override to force all browsers to use manual encoding
    this.recorderSrvc.config.usingMediaRecorder = false

    this.hasMediaRecorder = this.recorderSrvc.config.usingMediaRecorder
    this.recorderSrvc.em.addEventListener('recording', (evt) => this.onNewRecording(evt))
  },
}
</script>

<style scoped>
/* ----- Audio Player -----*/

[id^="recorder-control-"] {
  opacity: 0;
  visibility: hidden;
  height: 0;
  width: 0;
}

label[for^="recorder-control-"] {
  background-color: #EC445A;
  border-radius: 4px;
  padding: 5px;
  margin: 4px 2px;
  height: 36px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  letter-spacing: 0.84px;
  font-weight: 500;
  cursor: pointer;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

label[for^="recorder-control-"] ion-icon {
  font-size: 20px;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 10px;
}

/* ----- Audio Player -----*/

.audio-uploader .controls-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 20px;
}

.audio-uploader .controls-container ion-button {
  flex-basis: 100%;
  height: 36px;
}

@media all and (max-width: 600px) {
  .audio-uploader .controls-container ion-button {
    flex-basis: 100%;
  }
}

.audio-player {
  width: 100%;
  max-width: 800px;
  height: 36px;
  display: flex;
  margin: 10px auto;
}

.audio-record-button {
  --ion-color-base: #3DC2FF !important;
  --ion-color-shade: #20b9ff !important;
  --ion-color-tint: #20b9ff !important;
}

.mic-icon {
  padding-left: 4px;
}
</style>

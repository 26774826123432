<template>
  <section class="video-cap-container video-capture-container full-width ion-padding-horizontal" v-if="isValid">
    <!-- No recording -->
    <div v-show="!recordedVideo && !isRecording">
      <ion-button @click="connectMediaDevice()" size="block" color="danger">
        <slot name="start-recording-content">
          {{ recordBtnContent }}
          <ion-icon :icon="videocam" class="ion-padding"></ion-icon>
        </slot>
      </ion-button>
    </div>

    <!-- Recording -->
    <div v-show="isRecording">
      <ion-button
          v-if="isRecording"
          @click="stop()"
          size="block"
          color="danger"
      >
        <slot name="stop-recording-content">
          {{ stopBtnContent }}
          <ion-icon :icon="stopCircleOutline" class="ion-padding"></ion-icon>
        </slot>
        {{ formattedDuration(currentDuration) }}
      </ion-button>

      <div class="stream-container">
        <video ref="recording-preview-media" class="camera muted autoplay"/>
      </div>
    </div>

    <!-- Has recorded src -->
    <div v-show="recordedVideo">
      <div class="recorded-container">
        <video ref="recording-media" controls/>

        <slot name="delete-recording-content">
          <ion-button @click="deleteVideo()" expand="block" fill="outline">
            {{ DeleteBtnContent }}
          </ion-button>
        </slot>
      </div>
    </div>
  </section>
</template>

<script>
import {IonButton, IonIcon, alertController, isPlatform} from "@ionic/vue";
import {videocam, playCircle, mic, stopCircleOutline} from "ionicons/icons";
import { formattedDuration } from "@/composables/MediaDuration"

export default {
  name: "VideoCapture",
  setup() {
    const permissionsAlert = async () => {
      const alert = await alertController.create({
        header: "Alert",
        mode: (isPlatform("ios") ? "ios" : "md"),
        subHeader: "Permission Error",
        message: `<p>Please grant access to your devices Camera / Microphone and try again.</p>
        <p>How to change permissions in:</p>
        <p><a href="https://support.apple.com/en-gb/guide/safari/ibrwe2159f50/mac" target="_blank">iOS - Safari</p>
        <p><a href="https://support.google.com/chrome/answer/114662?hl=en&co=GENIE.Platform%3DAndroid&oco=0" target="_blank">Android - Chrome</p>`,
        buttons: ["OK"],
      });

      await alert.present();
    };

    const genericErrorAlert = async (
        genericMessage = "<p>There has been a problem accessing your device media, please try again.</p>"
    ) => {
      const alert = await alertController.create({
        header: "Alert",
        mode: (isPlatform("ios") ? "ios" : "md"),
        subHeader: "Error Accessing Device Media",
        message: genericMessage,
        buttons: ["OK"],
      });

      await alert.present();
    };

    return {
      videocam,
      playCircle,
      mic,
      stopCircleOutline,
      permissionsAlert,
      genericErrorAlert,
      formattedDuration
    };
  },
  components: [IonButton, IonIcon],
  props: {
    inputId: {
      default: "video-recorder-default",
    },
    stopMedia: {
      default: false,
    },
    uploadUrl: {
      default: null,
    },
    recordBtnContent: {
      default: "Record Video",
    },
    stopBtnContent: {
      default: "Stop recording",
    },
    DeleteBtnContent: {
      default: "Delete",
    },
  },
  data() {
    return {
      isValid: true,
      isUploading: false,
      isRecording: false,
      isFinished: false,
      recorder: null,
      videoUrl: null, // assigned when done writing video file
      stream: null,
      recordedVideo: null,
      currentDuration: 0
    };
  },
  methods: {
    // 1. - Setup initial values and attempt to connect with the devices media.
    connectMediaDevice() {
      // Set initial vars
      this.isFinished = false;
      this.isRecording = false;
      this.isLoading = true;
      this.$refs["recording-preview-media"].muted = true;
      this.$refs["recording-preview-media"].autoplay = true;

      // Connecting to device media
      navigator.mediaDevices
          .getUserMedia({
            video: {
              width: {ideal: 350},
              height: {ideal: 200},
            },
            audio: true,
          })
          //Start the recording process
          .then(this.setupStream)
          .catch((e) => {
            console.log(e);

            if (e.name == "NotAllowedError") {
              // Unable to access media device due to device or app permissions
              this.permissionsAlert();
            } else {
              // Unable to access media device / doesnt exist etc.
              this.genericErrorAlert();
            }
            // Ensure the promise fails and doesnt attempt to start the recording process
            Promise.reject("Failed to get media device");
          });
    },

    // 2. - Establish a connection with the device and return a new mediaRecording interface
    async setupStream(mediaStream) {
      this.stream = mediaStream;
      let errorCount = 0;
      // Attempt to setup a new mediaRecorder interface for non iOS devices that support webm
      try {
        this.recorder = new MediaRecorder(mediaStream, {
          mimeType: "video/webm",
          audioBitsPerSecond: 128000,
          videoBitsPerSecond: 2500000,
        });
      } catch (err1) {
        errorCount++;
        // Attempt to setup a new mediaRecorder interface for iOS devices which support mp4
        try {
          this.recorder = new MediaRecorder(mediaStream, {
            mimeType: "video/mp4",
            audioBitsPerSecond: 128000,
            videoBitsPerSecond: 2500000,
          });
        } catch (err2) {
          errorCount++;
          // If fallback doesn't work either. Log / process errors.
          console.error({err1});
          console.error({err2});
          this.stop(); // Stop the stream since we can't record with the above MIME types
        }
      } finally {
        // If we can set any of the MIME types then we can start recording
        if (errorCount < 2) {
          this.record(mediaStream);
        } else {
          Promise.reject("Failed to set MIME Type");
          let erorrContent = `
          <p>Could not set MIME Type</p>
          `;
          this.genericErrorAlert(erorrContent);
        }
      }
    },

    async sendVideo(rawBlob) {
      let formData = new FormData();
      let ctx = this;

      /* Add the form data we need to submit */
      formData.append("file", rawBlob);

      /* Make the request to the POST /single-file URL */
      await this.$http
          .post(
              process.env.VUE_APP_BACKEND_BASE_URL + '/api/auth/videostore',
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
          )
          .then(function (e) {
            console.log(
                "%c Video File Successfully Uploaded",
                "background: #222; color: #bada55"
            );
            console.log(e.data);
            ctx.videoUrl = e.data;

            // Server should probably error here but it currently throws text
            if (e.data == "no file") ctx.deleteVideo();

            return ctx.emitVideoFile();
          })
          .catch(function (e) {
            console.error("Failed to upload video");
            console.error(e);
            return ctx.deleteVideo();
          });
    },

    emitVideoFile(didFail) {
      if (didFail) this.videoUrl = null;

      this.emitter.emit("videoUploadAttempt", {
        inputId: this.inputId,
        videoUrl: this.videoUrl,
      });
    },

    setupRecordedVideo() {
      // After Recording session has finished
      const arrayBuffer = this.recordedVideo;
      const blob = new Blob([arrayBuffer], {type: "video/mp4"});
      let videoBlob = URL.createObjectURL(blob);
      this.$refs["recording-media"].src = `${videoBlob}#t=0.1`;
      this.sendVideo(blob);
    },
    // start recording
    record(mediaStream) {
      this.recorder.start();
      this.isRecording = true;
      this.recorder.ondataavailable = this.videoDataHandler;
      this.$refs["recording-preview-media"].src = null;
      this.$refs["recording-preview-media"].srcObject = mediaStream;
      this.toggleVideo();
      this.startDurationTimer();
    },
    // stop recording
    stop() {
      if (this.recorder) {
        this.recorder.stop();
      }

      if (this.stream) {
        this.stream.getTracks().forEach(function (track) {
          track.stop();
        });
      }

      this.isRecording = false;
      this.isFinished = true;
    },
    // reset video diaply and emit video file url
    done() {
      this.connectMediaDevice();
      this.$emit("input", this.videoUrl);
    },
    // handle sending data for writing using the given WebSocket
    videoDataHandler(event) {
      this.isUploading = true;
      let reader = new FileReader();
      reader.readAsArrayBuffer(event.data);
      reader.onloadend = () => {
        this.recordedVideo = reader.result;
        this.setupRecordedVideo();
      };
    },
    // update video when file written
    updateVideoFile(fileName) {
      this.videoUrl = `https://${this.uploadUrl}/uploads/${fileName}.webm`;
      this.toggleVideo();
      this.$refs["recording-preview-media"].srcObject = null;
      this.$refs["recording-preview-media"].src = this.videoUrl + "#t=0.1";
      this.isUploading = false;
    },
    // toggle video display
    toggleVideo() {
      this.$refs["recording-preview-media"].loop =
          !this.$refs["recording-preview-media"].loop;
      this.$refs["recording-preview-media"].controls =
          !this.$refs["recording-preview-media"].controls;
    },
    deleteVideo() {
      this.recordedVideo = null;
      this.emitVideoFile("failed");
    },
    startDurationTimer() {
      let wait = ms => new Promise(resolve => setTimeout(resolve, ms));
      (async () => {
        while (this.isRecording) {
          this.currentDuration = this.$refs["recording-preview-media"].currentTime;
          await wait(1000);
        }
      })().catch(e => console.log(e));
    },
  },
  unmounted() {
    this.stop();
  },
  watch: {
    stopMedia: function () {
      // Watch prop from parent when pressing next section to stop
      this.stop();
    },
  },
};
</script>

<style scoped>
.stream-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.stream-container video {
  pointer-events: none;
  border-radius: 10px;
  width: 100%;
  max-width: 500px;
}

.recorded-container video {
  border-radius: 10px;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  display: block;
  margin-bottom: 30px;
}

.video-cap-container {
  padding: 0 20px;
}
</style>

<template>
  <div class="intro">
    <div class="intro-content">
      <div
          v-show="surveyImage"
          class="intro-image"
          :style="`background-image: url(${surveyImage})`"
      />

      <div class="intro-container" :class="{ 'has-image': surveyImage }">
        <h3 class="intro-title">
          {{ survey.title }}
        </h3>

        <p class="intro-description">
          {{ survey.description }}
        </p>

        <IonButton class="get-started-button" @click="emitIntroAccepted()"
        >Get Started
        </IonButton
        >
      </div>
    </div>
  </div>
</template>
<script>

import {IonButton} from "@ionic/vue";

export default {
  name: 'DeveloSurveyIntro',
  components: {
    IonButton
  },
  props: {
    introAccepted: Boolean,
    survey: {},
  },
  methods: {
    emitIntroAccepted() {
      this.$emit('intro-accepted', true);
    },
  },
  computed: {
    surveyImage() {
      if (!this.survey.image) return false;
      return `${process.env.VUE_APP_BACKEND_BASE_URL}/storage/${this.survey.image}`;
    },
  }
}
</script>
<style scoped>
.intro {
  max-width: 1000px;
  margin: 0 auto;
}

.intro .intro-container {
  padding: var(--ion-padding);
  text-align: center;
  min-height: 80vh;
  padding-bottom: 60px;
  position: relative;
}

.get-started-button {
  position: absolute;
  bottom: 0;
  left: 20px;
  right: 20px;
  width: auto;
}

.intro .intro-container.has-image {
  padding: 320px var(--ion-padding) 30px var(--ion-padding);
}

.intro .intro-description {
  color: #92949c;
  letter-spacing: 0.02em;
  line-height: 22px;
}

ion-button {
  width: 100%;
  height: 50px;
  margin-top: 20px;
  cursor: pointer;
}

.intro-image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  width: 100%;
  max-width: 768px;
  height: 100%;
  max-height: 300px;
  margin: 0 auto;
}
</style>
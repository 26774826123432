import {Geolocation} from "@ionic-native/geolocation";

export class GeoLocationService {
    constructor() {
        this.geolocation = Geolocation;

    }

    getPosition (){
       return this.geolocation.getCurrentPosition().then((resp) => {
           console.log('Fetching cords',resp.coords);
           if("undefined" !== typeof resp.coords){
               return resp.coords;
           }
        }).catch((error) => {
            console.log('Error getting location', error);
        });
    }

    async getLatLong(){
        return await this.geolocation.getCurrentPosition().then((resp) => {
            // console.log(`Latitude: ${ resp.coords.latitude }, Longitude: ${ resp.coords.longitude }`);

            if("undefined" !== typeof resp.coords) {
                // Set Geolocation inside of LS to use later when sending Survey to the server.
                let lsGeoLocation = `${ resp.coords.latitude }, ${ resp.coords.longitude }`
                localStorage.setItem('geolocation', lsGeoLocation);
            }


        }).catch((error) => {
            console.log('Error getting location', error);
        });
    }

}

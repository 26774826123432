<template>
  <ion-page>

    <HeaderNav :title="pageTitle"/>

    <IonContent class="survey-content-wrapper">

      <DeveloSurveyIntro v-if="!introAccepted"
                         :intro-accepted="introAccepted"
                         :survey="survey"
                         @intro-accepted="proceedToPrivacy()"/>

      <DeveloSurveyPrivacy v-if="introAccepted && !privacyAccepted"
                           :survey="survey"
                           :intro-accepted="introAccepted"
                           @privacy-confirmed="proceedToSurvey();"/>

      <div class="survey-content" v-show="surveyReady">

        <div class="" onsubmit="return false">

          <form action="#" id="survey-form">

            <div v-for="(section, a) in survey.sections" :key="a">

              <div v-show="section.section_number === activeSection">

                <div v-for="(question, b) in section.questions"
                     :key="b"
                     class="question">
                  <h2 class="question-title">
                    <span class="question-number">{{ getQuestionNumber(question.id) + 1 }}. </span>
                    {{ question.title }}
                    <span v-if="question.required" class="val-error">*</span>
                  </h2>

                  <p class="question-description"
                     :class="{ 'hidden' : question.type === 'select' }">
                    {{ question.description }}
                  </p>

                  <ion-item class="survey-questions-container no-inner-padding"
                            :class="{ 'no-border': question.type === 'video' }"
                            lines="none">

                    <!-- Component Types -->

                    <DeveloIonSelect v-if="question.type === 'select'"
                                     :question="question"
                                     v-model="inputData[question.id]"/>

                    <DeveloIonRadio v-if="question.type === 'radio'"
                                    :question="question"
                                    @update-radio-input="handleInputEmits($event)"/>

                    <DeveloIonText v-if="question.type === 'input'"
                                   :question="question"
                                   @update-text-input="handleInputEmits($event)"/>

                    <DeveloIonTextArea v-if="question.type === 'text'"
                                       :question="question"
                                       @update-text-input="handleInputEmits($event)"/>

                    <VideoCapture v-if="question.type === 'video'"
                                  v-model="inputData[question.id]"
                                  :inputId="`video-recorder-${question.id}`"
                                  :stopMedia="stopMedia"/>

                    <audio-uploader v-if="question.type === 'audio'"
                                    :inputId="`recorder-${question.id}`"
                                    :stopMedia="stopMedia"/>

                    <DeveloIonRange v-if="question.type === 'range'"
                                    :question="question"
                                    @update-range-input="handleInputEmits($event)"/>

                  </ion-item>
                </div>
              </div>
            </div>
          </form>

        </div>

        <ion-button @click="goNext()"
                    v-if="activeSection < survey.sections.length"
                    size="block" class="next-submit-button">
          Next
        </ion-button>
        <ion-button @click="goNext('submission')"
                    v-if="activeSection == survey.sections.length"
                    size="block" class="next-submit-button">
          Submit
        </ion-button>
      </div>
    </IonContent>
  </ion-page>
</template>

<script>
import {alertController, IonContent, IonItem, IonPage, isPlatform, popoverController} from "@ionic/vue";
import {mic, playCircle, videocam} from "ionicons/icons";
import {defineComponent} from "vue";
import HeaderNav from "../components/blocks/HeaderNav";
import {useRoute} from "vue-router";
import SyncingPopover from "../components/SyncingPopover";
import AudioUploader from "../components/survey/AudioUploader";
import {GeoLocationService} from "../composables/GeoLocationService";
import VideoCapture from "../components/vue-media-recorder/VideoCapture.vue";
import DeveloIonSelect from "@/components/survey/DeveloIonSelect";
import DeveloSurveyIntro from "@/components/survey/DeveloSurveyIntro";
import DeveloSurveyPrivacy from "@/components/survey/DeveloSurveyPrivacy";
import DeveloIonRadio from "@/components/survey/DeveloIonRadio";
import DeveloIonText from "@/components/survey/DeveloIonText";
import DeveloIonTextArea from "@/components/survey/DeveloIonTextArea";
import DeveloIonRange from "@/components/survey/DeveloIonRange";

export default defineComponent({
  name: "Survey",
  components: {
    DeveloIonRange,
    DeveloIonTextArea,
    DeveloIonText,
    DeveloIonRadio,
    DeveloSurveyPrivacy,
    DeveloSurveyIntro,
    DeveloIonSelect,
    IonPage,
    IonContent,
    HeaderNav,
    IonItem,
    AudioUploader,
    VideoCapture,
  },
  setup() {
    const route = useRoute();
    const {projectIndex, surveyIndex} = route.params;
    return {projectIndex, surveyIndex, videocam, mic, playCircle};
  },
  data() {
    return {
      activeSection: 0,
      imageElement: "",
      validVideo: false,
      questionCount: 0,
      questionOrder: [],
      readyToLoad: false,
      inputData: null,
      introAccepted: false,
      privacyAccepted: false,
      surveyReady: false,
      privacyChecked: false,
      stopMedia: false,
      missingFieldsData: [],
      emptyFieldsPresent: false,
    };
  },
  created() {
    this.inputData = {};
    for (let section of this.survey.sections) {
      for (let question of section.questions) {
        question.type === "input" || question.type === "radio"
            ? (this.inputData[question["id"]] = [])
            : (this.inputData[question["id"]] = null);
      }
    }
  },
  methods: {
    /** Handle emits from input types to set survey inputData values
     * @param { Object }
     * @param { String }  inputQuestionId - Question ID
     * @param { String }  inputEvent      - The input change value
     * @param { String }  [inputOption]   - The Input nested option (Radio Multiple / Text Line Multiple)
     */
    handleInputEmits({inputQuestionId, inputEvent, inputOption}) {
      if (!inputQuestionId) return;

      // The input option provided is the index so let 0 pass
      if (!inputOption && inputOption !== 0) {
        this.inputData[inputQuestionId] = inputEvent
      } else {
        this.inputData[inputQuestionId][inputOption] = inputEvent;
      }
    },
    proceedToPrivacy() {
      this.introAccepted = true
    },
    proceedToSurvey() {
      this.privacyAccepted = true;
      this.surveyReady = true;
      this.activeSection = 1;
    },
    scrollToTop() {
      let content = document.getElementsByClassName(
          "survey-content-wrapper"
      )[0];
      content.scrollToPoint(0, 0, 300);
    },
    async goNext(submission) {
      this.stopMedia = !this.stopMedia; // Using this as a switcher ev that AudioUploader can watch as a prop to pause any playing audio constructors
      await this.missingFields();

      if (submission) {
        return this.emptyFieldsPresent
            ? this.presentMissingRequiredFields(this.missingFieldsData)
            : this.sendData();
      } else {
        return this.emptyFieldsPresent
            ? this.presentMissingRequiredFields(this.missingFieldsData)
            : this.activeSection++ && this.scrollToTop();
      }
    },
    missingFields() {
      this.missingFieldsData = [];
      this.emptyFieldsPresent = false;

      for (let section of this.survey.sections) {
        let currentSection = section.section_number;
        if (currentSection == this.activeSection) {
          // Loop over current active survey questions
          for (let question of section.questions) {
            // Loop over all inputData and get Index
            for (let inputDataKey in this.inputData) {
              let inputDataValue = this.inputData[inputDataKey];
              // Check if InputData Id matches the question Id
              if (inputDataKey == question.id) {
                if (
                    question.required &&
                    (!inputDataValue || inputDataValue == "")
                ) {
                  this.emptyFieldsPresent = true;
                  this.missingFieldsData.push(question.title);
                  console.log(question.title);
                }
              }
            }
          }
        }
      }
    },
    isInputDataDirty() {
      for (let inputDataKey in this.inputData) {
        let inputDataValue = this.inputData[inputDataKey];
        if (
            inputDataValue == null ||
            (Array.isArray(inputDataValue) && !inputDataValue.length)
        ) {
          // No dirty input
        } else {
          // Is Dirty Input
          return true;
        }
      }
    },
    goBack(refresh) {
      // This is running instead of header based on the survey page being present due to emit
      let isDirty = this.isInputDataDirty();

      return refresh
          ? this.$router.go(-1) // If refresh arg is present then go back a page (e.g. for a reload after survey submission without needing to relog)
          : this.activeSection >= 2
              ? this.activeSection-- // If you can navigate multiple sections then do so
              : this.activeSection === 1 && isDirty
                  ? this.presentAlertMultipleButtons() // If you are on section 1 and any inputs have been made dirty then prompt warning
                  : this.activeSection === 1
                      ? this.$router.go(-1) // If you are on section 1, no dirty inputs then just go back
                      : this.activeSection === 0
                          ? this.$router.go(-1) // If you are on section 0 (have not accepted privacy declaration etc.) then just go back
                          : false;
    },
    async presentAlertMultipleButtons() {
      const alert = await alertController.create({
        cssClass: "my-custom-class",
        mode: (isPlatform("ios") ? "ios" : "md"),
        header: "Are you sure you want to go back?",
        subHeader: "",
        message: "Going back will delete all your current progress",
        buttons: [
          {
            text: "Cancel",
            role: "cancel", // Role of cancel will use this when user clicks out of bounds, default to close
            cssClass: "secondary",
          },
          {
            text: "Go Back",
            cssClass: "secondary",
            handler: () => {
              console.log("Confirm go back a page");
              return this.goBack("refresh"); // Force go back and skip other checks
            },
          },
        ],
      });
      return alert.present();
    },
    async presentMissingRequiredFields(missingFieldsData) {
      const alert = await alertController.create({
        cssClass: "my-custom-class",
        mode: (isPlatform("ios") ? "ios" : "md"),
        header: "Please fill in the required questions",
        subHeader: "",
        message:
        // Loop over the array that contains missing fields and format each in a p
            `${missingFieldsData
                .map((missingTitle) => `<p>${missingTitle}</p>`)
                .join("")}`,
        buttons: [
          {
            text: "Ok",
            role: "cancel", // Role of cancel will use this when user clicks out of bounds, default to close
            cssClass: "secondary",
          },
        ],
      });
      return alert.present();
    },
    getQuestionNumber(id) {
      return this.questionOrder.indexOf(id);
    },
    sendData() {
      var requestData = {
        survey_id: this.surveyId,
        answers: this.inputData,
        user_id: window.user.user_id,
        geolocation: this.deviceLatLong,
        source: this.source,
        shared_hash: window.user.shared_hash
      };

      let today = new Date();

      var localStorageSubmission = {
        projectTitle: this.project["title"],
        surveyTitle: this.survey["title"],
        requestData: requestData,
        synced: false,
        date: today.toLocaleDateString("en-GB"),
        time: today.getHours() + ":" + today.getMinutes(),
      };

      // Get all current submissions and merge this one with it.
      var allSubmissions = [];
      if (localStorage.getItem("submissions") !== null) {
        allSubmissions = JSON.parse(localStorage.getItem("submissions"));
      }

      // Send submission to the local storage
      allSubmissions.push(localStorageSubmission);
      localStorage.setItem("submissions", JSON.stringify(allSubmissions));

      this.$http({
        url: process.env.VUE_APP_BACKEND_SUBMIT_SURVEY,
        method: "post",
        data: requestData,
      })
          .then(() => {
            // Grab all submissions from LS
            var allSubmissions = [];
            if (localStorage.getItem("submissions") !== null) {
              allSubmissions = JSON.parse(localStorage.getItem("submissions"));

              // Find and update our current survey submission sync state.
              for (let submissionFromLs in allSubmissions) {
                if (
                    allSubmissions[submissionFromLs].requestData.survey_id ===
                    this.survey["id"]
                ) {
                  allSubmissions[submissionFromLs].synced = true;
                }
              }
            }

            // Save our new state of submissions
            localStorage.setItem("submissions", JSON.stringify(allSubmissions));

            // Emit Sync success so Home can re-render properly
            this.emitter.emit("sync-success");

            this.openPopover("success");

            setTimeout(() => {
              this.closePopover();
            }, 2000);

            if(!window.isSharedSurvey) {
              setTimeout(() => {
                this.goBack('refresh')
              }, 2000)
            }
            else {
              setTimeout(() => {
                this.$router.push('/shared-submitted');
              }, 1000)
            }
          })
          .catch(async (error) => {
            // Update the local storage for this item .synced = false
            console.error(error);
            this.emitter.emit("sync-success");

            this.openPopover("failed");

            setTimeout(() => {
              this.closePopover();
            }, 2000);

            setTimeout(() => {
              this.goBack("refresh");
            }, 2000);
          });
      this.inputData = {};
    },
    async openPopover(status) {
      this.popover = await popoverController.create({
        component: SyncingPopover,
        cssClass: "develo-ui-survey-state--center",
        componentProps: {
          success: status,
        },
        mode: (isPlatform("ios") ? "ios" : "md"),
      });
      return this.popover.present();
    },
    async closePopover() {
      if (this.popover) {
        return this.popover.dismiss();
      }
    },
  },
  computed: {
    deviceLatLong() {
      // Check for geo LS item otherwise return null
      return localStorage.getItem("geolocation")
          ? localStorage.getItem("geolocation")
          : null;
    },
    survey() {
      return this.project["surveys"][this.surveyIndex];
    },
    project() {
      return window.projects[this.projectIndex];
    },
    surveyLength() {
      return this.survey.sections.length;
    },
    surveyId() {
      return this.survey.id;
    },
    source() {
      if(window.isSharedSurvey === true){
        return window.user.source;
      }
      else {
        return "app";
      }
    },
    pageTitle() {
      let activeSectionTitle = this.survey.sections[0].title;
      for (let section of this.survey.sections) {
        if (section.section_number == this.activeSection) {
          activeSectionTitle = section.title;
        }
      }
      return !this.introAccepted
          ? "Survey Intro"
          : !this.privacyAccepted
              ? "Privacy Declaration"
              : `${this.activeSection} / ${this.surveyLength} ${activeSectionTitle}`;
    },
  },
  beforeMount() {
    let sections = this.survey.sections;
    for (let i of sections) {
      // console.log(i);
      for (let j of i.questions) {
        this.questionOrder.push(j.id);
      }
    }
  },
  async mounted() {
    // Run func for getting Lat Long data from user device
    const geolocation = new GeoLocationService();
    await geolocation.getLatLong();

    let externalScript = document.createElement("script");
    externalScript.setAttribute(
        "src",
        "https://widget.cloudinary.com/v2.0/global/all.js"
    );
    document.head.appendChild(externalScript);

    this.emitter.on("send-video-url", (payload) => console.log(payload));
    this.emitter.on("goBackSection", () => this.goBack());
    this.emitter.on("audioUploadAttempt", (payload) => {
      let strippedId = payload.inputId.substr(9); // Strip Id back to just question.Id
      this.inputData[strippedId] = payload.audioFile; // Set the inputData var for that particular input to audio file location
    });
    this.emitter.on("videoUploadAttempt", (payload) => {
      let strippedId = payload.inputId.substr(15); // Strip Id back to just question.Id (removing 'video-recorder-control-')
      this.inputData[strippedId] = payload.videoUrl; // Set the inputData var for that particular input to audio file location
    });
    console.log(this.survey);
  },
  unmounted() {
    this.emitter.all.clear();
  },
});
</script>

<style scoped>
.content-container {
  padding: 0 var(--ion-padding);
}

.survey-content {
  min-height: 80vh;
  padding-bottom: 60px;
  position: relative;
}

.next-submit-button {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.question {
  margin-bottom: 30px;
}

.question-number {
  display: inline-block;
}

.question-title {
  padding: 0 20px;
  font-size: 22px;
  font-weight: 400;
}

.question-description {
  padding: 0 20px;
  color: var(--survey-description-color);
  font-size: 14px;
}

.survey-questions-container {
  --border-color: var(--ion-border-color);
  --border-width: 0;
}

.select-option-container ion-select {
  min-width: 100%;
  margin: 0;
}

.privacy-content p {
  margin-top: 0;
}

.privacy ion-item {
  text-align: center;
}

@media all and (max-width: 375px) {
}

.privacy ion-checkbox {
  margin-right: 10px;
}

ion-button {
  height: 50px;
  margin: 20px;
  cursor: pointer;
}

/* ----- Ion Segment Style Override ----- */

ion-segment {
  padding: 3px 1px;
  --background: #f0f0f0;
}

ion-segment-button {
  min-width: auto;
  --background: #f0f0f0;
  --background-checked: white;
  --background-focused: white;
  --background-hover: white;
  --indicator-color: transparent;
}

.segment-button-checked {
  box-shadow: rgba(0, 0, 0, 0.1) 0 0 6px -1px, rgba(0, 0, 0, 0.1) 0 2px 4px -1px;
  background-color: lightgreen;
  --border-radius: 10px;
  border: 1px solid #3880ff;
}

ion-segment-button:hover {
  /*background-color: rgba(255, 255, 255, 0.6);*/
  background-color: white;
  border-radius: 10px;
  --border-radius: 10px;
}

ion-segment-button::part(native) {
  border-radius: 10px;
}

ion-segment-button ion-label {
  font-size: 13px !important;
}

/* ----- Ion Range Style ----- */

.val-error {
  font-size: 16px;
  color: #EC445A;
}

.bys-front-logo {
  position: relative;
  top: -10px;
}

.bys-front-logo ion-img {
  max-width: 150px;
  width: 100%;
  height: auto;
  margin: 0 auto;
}

.bys-front-logo .logo-secondary {
  max-width: 140px;
}

</style>

<template>
  <ion-header :translucent="true">
    <ion-toolbar>
      <ion-buttons slot="start" class="go-back">
        <ion-button
          color="secondary"
          @click="goBack"
          v-if="$route.name !== 'Home'"
          v-show="!shared"
        >
          <ion-icon :icon="chevronBackOutline" />
          <span>Back</span>
        </ion-button>
      </ion-buttons>

      <ion-title
          :class="$route.name == 'Home' ? 'home' : ''"
          class="ion-title ion-text-center"
      >
        {{ title ? title : $route.name }}
      </ion-title
      >

      <ion-buttons slot="end" class="ion-justify-content-end" v-show="!shared">

        <ion-button color="secondary" @click="openPopover">
          <ion-icon slot="icon-only" :ios="ellipsisHorizontal"/>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
</template>

<script>
import {
  IonHeader,
  IonTitle,
  IonToolbar,
  IonIcon,
  popoverController,
  IonButton,
  IonButtons,
  isPlatform
} from "@ionic/vue";
import {
  ellipsisHorizontal,
  ellipsisVertical,
  chevronBackOutline,
} from "ionicons/icons";
import {defineComponent} from "vue";

import MenuPopover from "../MenuPopover";

export default defineComponent({
  name: "HeaderNav",
  components: {
    IonHeader,
    IonTitle,
    IonToolbar,
    IonIcon,
    IonButton,
    IonButtons,
  },
  props: {
    title: {
      type: String,
    },
  },
  data() {
    return {
      shared: !!window.user.sharer_id,
    }
  },
  setup() {
    return {
      ellipsisHorizontal,
      ellipsisVertical,
      chevronBackOutline,
    };
  },
  methods: {
    goBack() {
      // If you are on the survey page, delegate this job to that component via emit and run a different goBack() otherwise just go back a page
      return this.$route.name === "Survey"
          ? this.emitter.emit("goBackSection", true)
          : this.$router.go(-1);
    },
    async openPopover(e) {
      const popover = await popoverController.create({
        component: MenuPopover,
        cssClass: "develo-ui-menu",
        translucent: true,
        event: e,
        showBackdrop: false,
        mode: (isPlatform("ios") ? "ios" : "md")
      });
      return popover.present();
    },
  },
});
</script>

<style scoped>
.go-back {
  cursor: pointer;
}

.go-back ion-button > span {
  text-transform: capitalize;
  font-size: 16px;
  line-height: 17px;
}

ion-toolbar {
  min-height: 64px;
  display: flex;
  align-items: center;
}

ion-header::after {
  background: none !important;
  border-bottom: 1px solid #0000001a;
  bottom: -1px !important;
}
</style>

<template>
  <ion-page>
    <header-nav/>
    <ion-content :fullscreen="true">
      <ion-list>
        <router-link
            v-for="(project, projectIndex) in projects"
            :key="project.id"
            :to="'/surveys/' + projectIndex"
        >
          <ion-icon class="album-icon" :icon="albumsSharp"></ion-icon>
          <ion-item>
            <ion-label>
              {{ project.title }}
            </ion-label>
          </ion-item>
        </router-link>
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonItem,
  IonLabel,
  IonList,
  IonContent,
  IonIcon,
  popoverController,
  IonPage,
  isPlatform
} from "@ionic/vue";
import {ellipsisHorizontal, ellipsisVertical} from "ionicons/icons";
import {defineComponent} from "vue";
import {albumsSharp} from "ionicons/icons";
import HeaderNav from "../components/blocks/HeaderNav";

import Popover from "../components/MenuPopover";

export default defineComponent({
  name: "Projects",
  components: {
    IonContent,
    IonIcon,
    IonLabel,
    IonList,
    IonItem,
    HeaderNav,
    IonPage,
  },
  computed: {
    projects() {
      return window.projects;
    },
  },
  setup() {
    return {
      ellipsisHorizontal,
      ellipsisVertical,
      albumsSharp,
    };
  },
  methods: {
    async openPopover(e) {
      const popover = await popoverController.create({
        component: Popover,
        cssClass: "my-custom-class",
        translucent: true,
        event: e,
        mode: (isPlatform("ios") ? "ios" : "md"),
      });
      return popover.present();
    },
  },
});
</script>

<style scoped>

#container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

#container a {
  text-decoration: none;
}

.ion-title {
  font-weight: 600;
}

ion-list {
  margin-left: var(--ion-margin);
}

ion-list a {
  display: flex;
  align-items: center;
}

ion-icon {
  color: #222428;
  width: 28px;
  height: 28px;
}

ion-item {
  width: calc(100% - 22px);
}
</style>

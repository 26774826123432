import { createRouter, createWebHistory } from '@ionic/vue-router';
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Projects from '../views/Projects.vue'
import Surveys from '../views/Surveys.vue'
import Survey from '../views/Survey.vue'
import Privacy from '../views/Privacy.vue'
import SharedSubmitted from '../views/SharedSubmitted.vue'

const routes = [
  { path: '/', redirect: '/login' },
  { path: '/home', name: 'Home', component: Home },
  { path: '/login',  name: 'Login', component: Login },
  { path: '/projects', name: 'Projects', component: Projects },
  {
    path: '/surveys/:projectIndex',
    name: 'Surveys',
    component: Surveys
  },
  {
    path: '/survey/:projectIndex/:surveyIndex',
    name: 'Survey',
    component: Survey,
    meta: { requiresAuth: false }
  },
  { path: '/privacy', name: 'Privacy', component: Privacy },
  { path: '/shared-submitted', name: 'Submitted', component: SharedSubmitted }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name != 'Login' && (typeof window.user == 'undefined' || typeof window.user.user_id == 'undefined')) {
    next({ name: 'Login' })
  } else {
    next()
  }
})

export default router

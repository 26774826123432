<template>
  <ion-app>
    <ion-router-outlet/>
  </ion-app>
</template>

<script>
    import {IonApp, IonRouterOutlet} from '@ionic/vue';
    import {defineComponent} from 'vue';
    import jQuery from 'jquery'

    window.jQuery = jQuery;

    export default defineComponent({
        name: 'App',
        components: {
            IonApp,
            IonRouterOutlet
        }
    });
</script>

<style>
  a {
    text-decoration: none;
  }

  ion-content {
    padding-bottom: 50px;
  }

 #app, ion-app {
    max-width: 768px;
    width: 100%;
    margin: 0 auto;
    box-shadow: rgb(99 99 99 / 20%) 0 2px 8px 0;
  }

  /* .develo-ui-menu .popover-content {
    top: 52px !important;
    left: unset !important;
    right: 10px !important;
    transform-origin: right top !important;
  } */

  .develo-ui-success .popover-content {
    position: unset !important;
  }

/* Removing x padding around ion-item */
   ion-item.no-inner-padding {
    --padding-start: 0;
    --inner-padding-start: 0;
    --padding-end: 0;
  --inner-padding-end: 0;
}

.no-border {
  --border-color: transparent !important;
  --border-width: 0 !important;
}

/* Adding in some custom input padding for the shadow input that isnt accessible from an exposed part attr */
ion-item.input-padding-left {
  --padding-start: 20px;
  --inner-padding-start: 20px;
}

ion-item.input-padding-right {
  --padding-end: 20px;
  --inner-padding-end: 20px;
}
</style>

<template>
  <ion-page>

    <header-nav/>

    <ion-content>
      <div class="submitted-message">
        <p>Survey has been submitted.</p>

        <ion-button size="block" @click="() => logout()">Close</ion-button>
      </div>

    </ion-content>
  </ion-page>
</template>

<script>
import {IonPage, IonContent, IonButton} from '@ionic/vue';
import { defineComponent } from 'vue';
import {useRouter} from 'vue-router';
import HeaderNav from '../components/blocks/HeaderNav'

export default defineComponent({
  name: "SharedSubmitted",
  components: {
    HeaderNav,
    IonPage,
    IonContent,
    IonButton
  },
  methods: {
    logout() {
      localStorage.removeItem('token')
      localStorage.removeItem('user')
      setTimeout(() => {
        this.$router.push('/login');
      }, 500)
    }
  },
  setup() {
    const router = useRouter();
    return {router};
  }
})
</script>

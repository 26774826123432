<template>
  <ion-page>
    <header-nav/>
    <ion-content :fullscreen="true">
      <ion-list v-if="surveysWithSections.length">
        <router-link
            v-for="(survey, surveyIndex) in surveysWithSections"
            :key="surveyIndex"
            :to="'/survey/' + projectIndex + '/' + surveyIndex"
        >
          <ion-icon class="album-icon" :icon="clipboard"></ion-icon>

          <ion-item>
            <ion-label>
              {{ survey.title }}
            </ion-label>
          </ion-item>
        </router-link>
      </ion-list>
      <p v-else class="no-surveys-text">
        There are currently no surveys assigned to this project
      </p>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonItem,
  IonLabel,
  IonList,
  IonContent,
  IonIcon,
  popoverController,
  IonPage,
  isPlatform
} from "@ionic/vue";
import {clipboard} from "ionicons/icons";
import {defineComponent} from "vue";
import HeaderNav from "../components/blocks/HeaderNav";

import Popover from "../components/MenuPopover";
import {useRoute} from "vue-router";

export default defineComponent({
  name: "Surveys",
  components: {
    IonContent,
    IonIcon,
    IonLabel,
    IonList,
    IonItem,
    HeaderNav,
    IonPage,
  },
  computed: {
    surveys() {
      return window.projects[this.projectIndex]["surveys"];
    },
    surveysWithSections() {
      if (this.surveysWithoutSections.length) {
        console.group("Survey List without sections");
        this.surveysWithoutSections.forEach((survey) => {
          console.error(survey.title);
        });
      }
      return this.surveys.filter((survey) => survey.sections.length);
    },
    surveysWithoutSections() {
      return this.surveys.filter((survey) => !survey.sections.length);
    },
  },
  setup() {
    const route = useRoute();
    const {projectIndex} = route.params;
    return {projectIndex, clipboard};
  },
  methods: {
    async openPopover(e) {
      const popover = await popoverController.create({
        component: Popover,
        cssClass: "my-custom-class",
        translucent: true,
        event: e,
        mode: (isPlatform("ios") ? "ios" : "md"),
      });
      return popover.present();
    },
  },
});
</script>

<style scoped>
#container {
  text-align: center;

  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;

  color: #8c8c8c;

  margin: 0;
}

#container a {
  text-decoration: none;
}

.ion-title {
  font-weight: 500;
}

ion-list {
  margin-left: var(--ion-margin);
}

ion-list a {
  display: flex;
  align-items: center;
}

ion-icon {
  color: #222428;
  width: 28px;
  height: 28px;
}

ion-item {
  width: calc(100% - 22px);
}

.no-surveys-text {
  text-align: center;
  padding: 0 20px;
}
</style>

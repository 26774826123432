<template>
  <div>
    <ion-item v-if="success === 'success'">
      <ion-label class="success">
        <span class="icon-success" />
        Success!
      </ion-label>
    </ion-item>

    <ion-item v-else-if="success === 'failed'">
      <ion-label class="failed">
        <span class="icon-failed" />
        Survey Submission Failed!
        <br />
        <span
          >(This has been stored locally and you can attempt to Sync again using
          the Home Menu)</span
        >
      </ion-label>
    </ion-item>

    <ion-item v-else>
      <ion-label>
        <ion-spinner name="lines"></ion-spinner>
        {{ title }}
      </ion-label>
    </ion-item>
  </div>
</template>

<script>
import { IonItem, IonLabel, IonSpinner } from "@ionic/vue";

export default {
  name: "SyncingPopover",
  components: { IonItem, IonLabel, IonSpinner },
  props: {
    success: {
      required: false,
    },
    title: {
      required: false,
      default: "Syncing",
    },
  },
};
</script>

<style scoped>
.lds-dual-ring {
  width: 80px;
  height: 80px;
  position: absolute;
  top: -12%;
  left: 29%;
}
.lds-dual-ring:after {
  content: " ";
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #b1b3b0 transparent #b1b3b0 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
  display: flex;
  position: relative;
  top: 30%;
  left: 30%;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

ion-label:not(.default) {
  text-align: center;
  padding: 20px 0;
  white-space: pre-wrap !important;
}

ion-spinner {
    margin-right: calc(var(--ion-margin) / 2);
}

.success,
.failed {
  margin-top: calc(var(--ion-margin) * 2);
}

.success {
  color: #2dd36f;
}

.failed {
  color: #cf3c4f;
}

.icon-success {
  -webkit-animation: none;
  animation: none;
  border-color: #5cb85c;
  transition: border opacity 500ms ease-out;
}
.icon-success:after {
  animation-duration: 1.2s;
  animation-timing-function: ease;
  animation-name: checkmark;
  transform: scaleX(-1) rotate(135deg);
  opacity: 1;
  height: 20px;
  width: 10px;
  transform-origin: left top;
  border-right: 2px solid #5cb85c;
  border-top: 2px solid #5cb85c;
  content: "";
  position: absolute;
  top: 34%;
  left: 42%;
}

.icon-failed {
  position: absolute;
  left: 42%;
  top: 15%;
}
.icon-failed:hover {
  opacity: 1;
}
.icon-failed:before,
.icon-failed:after {
  position: absolute;
  left: 15px;
  content: " ";
  height: 20px;
  width: 2px;
  background-color: red;
}
.icon-failed:before {
  transform: rotate(45deg);
}
.icon-failed:after {
  transform: rotate(-45deg);
}
</style>

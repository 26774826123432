<template>
  <div class="text-area-container full-width">
    <IonItem>
      <IonTextarea
          auto-grow="auto-grow"
          placeholder="Enter more information here"
          :maxlength="question.max_length"
          inputmode="text"
          :required="question.required"
          :name="question.type + question.id"
          :rows="question.lines ? question.lines : 3"
          cols="20"
          @ionChange="emitTextChange($event.target.value)"
      />
    </IonItem>
  </div>
</template>
<script>
import {IonItem, IonTextarea} from "@ionic/vue";

export default {
  name: 'DeveloIonTextArea',
  components: {
    IonItem,
    IonTextarea
  },
  props: {
    question: {}
  },
  methods: {
    emitTextChange(event) {
      this.$emit('update-text-input', {inputQuestionId: this.question.id, inputEvent: event});
    }
  }
}
</script>
<style scoped>
ion-textarea {
  --padding-bottom: 20px;
}

.question ion-item:last-of-type {
  margin-bottom: 30px;
}
</style>
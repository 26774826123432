<template>

  <ion-page>

    <ion-content v-show="loadPage">
      <ion-grid class="ion-no-padding">
        <ion-row>
          <ion-col class="ion-no-padding">
            <div v-show="top_image" class="top-image"></div>

            <div class="login-section-wrapper">
              <div class="bys-front-logo ion-padding-horizontal">
                <ion-img v-if="logo" :src="logo" class="logo-primary"></ion-img>
                <div v-else class="app-brand-name">{{ app_name }}</div>
              </div>


              <form>
                <ion-grid class="ion-no-padding">
                  <ion-row color="primary" justify-content-center>

                    <ion-col align-self-center>
                      <div class="login-text ion-padding-horizontal">
                        <h1>Log in</h1>
                      </div>

                      <ion-item
                          class=" no-inner-padding input-padding-left input-padding-right"
                      >
                        <ion-label position="stacked ">Email</ion-label>
                        <ion-input
                            name="email"
                            type="email"
                            placeholder="user@email.com"
                            v-model="email"
                            required
                            @keyup.enter="login"
                        ></ion-input>
                      </ion-item>

                      <ion-item
                          class=" no-inner-padding input-padding-left input-padding-right"
                      >
                        <ion-label position="stacked ">Password</ion-label>
                        <ion-input
                            name="password"
                            type="password"
                            placeholder="********"
                            v-model="password"
                            required
                            @keyup.enter="login"
                        ></ion-input>
                      </ion-item>

                      <div
                          class=" ion-text-center ion-padding-top ion-padding-horizontal"
                      >
                        <ion-button size="block" @click="login"
                        >Login
                        </ion-button
                        >
                      </div>

                      <div
                          class=" forgotten-password ion-text-center ion-padding ion-padding-horizontal"
                      >
                        <p @click="openForgotPass()">Forgotten Password</p>
                      </div>

                      <!-- <div class="association--container login-footer">
                        <div v-html="login_footer"></div>
                      </div> -->
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </form>
            </div>

          </ion-col>

        </ion-row>

      </ion-grid>

    </ion-content>

  </ion-page>

</template>

<script>
import {
  IonCol,
  IonGrid,
  IonRow,
  IonLabel,
  IonInput,
  IonItem,
  IonContent,
  IonPage,
  IonButton,
  toastController,
  IonImg,
} from "@ionic/vue";
import {defineComponent} from "vue";
import {useRouter} from "vue-router";

export default defineComponent({
  name: "Login",
  components: {
    IonCol,
    IonGrid,
    IonRow,
    IonLabel,
    IonInput,
    IonItem,
    IonContent,
    IonPage,
    IonButton,
    IonImg,
  },
  setup() {
    const router = useRouter();
    return {router};
  },
  data() {
    return {
      email: null,
      password: null,
      invalidCredentials: false,
      favicon: null,
      top_image: null,
      logo: null,
      app_name: null,
      login_footer: null,
      primary_colour: "#3880ff",
      secondary_colour: "#f5f5f5",
      imageBase64: "",
      loadPage: localStorage.getItem("submissions") === null ? false : true, // have to check for local storage otherwise after hash submission login page doesn't load.
    };
  },

  async created() {
    this.$http({
      url: process.env.VUE_APP_BACKEND_API_SETTINGS,
      method: "get",
    })
        .then((response) => {
          window.settings = response.data;

          this.top_image = window.settings.landing_image
              ? process.env.VUE_APP_BACKEND_BASE_URL +
              "/storage/" +
              window.settings.landing_image
              : false;

          this.favicon = window.settings.favicon
              ? process.env.VUE_APP_BACKEND_BASE_URL +
              "/storage/" +
              window.settings.favicon
              : false;

          // Adding favicon to head
          const head = document.getElementsByTagName('head'),
              faviconLink = document.createElement("link");
          faviconLink.setAttribute('rel', 'shortcut icon');
          faviconLink.setAttribute('type', 'image/png');
          faviconLink.setAttribute('href', this.favicon);
          head[0].appendChild(faviconLink);

          if (this.top_image) {
            window.jQuery(".login-section-wrapper").css("margin-top", "280px");
            window
                .jQuery(".top-image")
                .css("background-image", "url('" + this.top_image + "')");
          }
          this.logo = window.settings.logo
              ? process.env.VUE_APP_BACKEND_BASE_URL +
              "/storage/" +
              window.settings.logo
              : false;
          this.login_footer = window.settings.login_footer;
          this.primary_colour = window.settings.primary_colour;
          this.secondary_colour = window.settings.primary_colour;

          document.title = window.settings.app_name;
          this.app_name = window.settings.app_name;

          //setting css variables
          window.jQuery(":root").css("--ion-color-primary", this.primary_colour);
          window
              .jQuery(":root")
              .css(
                  "--ion-color-primary-shade",
                  this.LightenDarkenColor(this.primary_colour, -20)
              );
          window
              .jQuery(":root")
              .css(
                  "--ion-color-primary-tint",
                  this.LightenDarkenColor(this.primary_colour, 20)
              );
          window
              .jQuery(":root")
              .css("--ion-color-secondary", this.secondary_colour);
          window
              .jQuery(":root")
              .css(
                  "--ion-color-secondary-shade",
                  this.LightenDarkenColor(this.secondary_colour, -20)
              );
          window
              .jQuery(":root")
              .css(
                  "--ion-color-secondary-tint",
                  this.LightenDarkenColor(this.secondary_colour, 20)
              );
        })
        .catch((error) => {
          console.error(error);
        });

  },

  async mounted() {

    this.share_hash = this.$route.query.hash;
    this.projectId = 0;
    this.surveyId = 0;
    if (this.share_hash) {
      this.$http({
        url: process.env.VUE_APP_BACKEND_BASE_URL + '/api/survey-shared',
        method: 'post',
        data: {
          hash: this.share_hash,
        }
      }).then((response) => {
        if (typeof response.data !== 'undefined') {

          window.shared_survey = response.data;
          window.isSharedSurvey = true;
          // window.user = {
          //   "user_id": this.share_hash
          // }
          this.$http.defaults.headers.common['Authorization'] = `Bearer ${response.data.access_token}`

          localStorage.setItem("token", response.data.access_token);
          localStorage.setItem("user", JSON.stringify(response.data));
          window.user = response.data;
          window.projects = response.data.processedProjects;
          this.projectId = response.data.project_id;
          this.surveyId = response.data.survey_id;

        }
      }).then(() => {
        this.$router.push('/survey/' + 0 + '/' + 0);
      })
          .catch((error) => {
            this.$router.push('/login');
            this.loadPage = true;
            this.openUnsuccessfulHashToast(error.response.data.error)
          });
    } else {
      var token = localStorage.getItem("token");
      var user = localStorage.getItem("user");

      if (token && user) {
        // parse here when token has been set
        window.user = JSON.parse(user);

        this.$http.defaults.headers.common['Authorization'] = `Bearer ${token}`

        this.$http({
          url: process.env.VUE_APP_BACKEND_API_DATA,
          method: "get",
        })
            .then((response) => {
              window.projects = response.data;
              this.$router.push("/home");
            })
            .catch((error) => {
              console.error(error);
              this.loadPage = true;
            });
      } else {
        this.loadPage = true;
      }

      this.emitter.on("loadLogin", () => (this.loadPage = true));
    }
  },

  methods: {
    callback(data) {
      console.debug(data);
    },
    openForgotPass() {
      window.open(process.env.VUE_APP_BACKEND_FORGOT_PW);
    },
    async openInvalidCredentialsToast() {
      const toast = await toastController.create({
        message: "Invalid email or password.",
        duration: 3000,
        color: "danger",
      });
      return toast.present();
    },
    async openUnsuccessfulHashToast(message) {
      const toast = await toastController.create({
        message: message,
        duration: 3000,
        color: "danger",
      });
      return toast.present();
    },
    async login() {
      this.invalidCredentials = false; // reset

      this.$http({
        url: process.env.VUE_APP_BACKEND_API_LOGIN,
        method: "post",
        data: {
          email: this.email,
          password: this.password,
        },
      })
          .then((response) => {
            if (
                typeof response.data !== "undefined" &&
                typeof response.data.access_token !== "undefined"
            ) {
              this.$http.defaults.headers.common[
                  "Authorization"
                  ] = `Bearer ${response.data.access_token}`;

              localStorage.setItem("token", response.data.access_token);
              localStorage.setItem("user", JSON.stringify(response.data));

              window.user = response.data;

              this.$http({
                url: process.env.VUE_APP_BACKEND_API_DATA,
                method: "get",
              })
                  .then((response) => {
                    window.projects = response.data;
                    this.$router.push("/home");
                  })
                  .catch((error) => {
                    console.error(error);
                  });
            }
          })
          .catch((error) => {
            if (
                typeof error.response !== "undefined" &&
                typeof error.response.status !== "undefined" &&
                error.response.status === 401
            ) {
              // Wrong auth credentials
              this.invalidCredentials = true;
            } else {
              console.error(error);
            }
          });
    },

    LightenDarkenColor(col, amt) {

      var usePound = false;

      if (col[0] == "#") {
        col = col.slice(1);
        usePound = true;
      }

      var num = parseInt(col, 16);

      var r = (num >> 16) + amt;

      if (r > 255) r = 255;
      else if (r < 0) r = 0;

      var b = ((num >> 8) & 0x00ff) + amt;

      if (b > 255) b = 255;
      else if (b < 0) b = 0;

      var g = (num & 0x0000ff) + amt;

      if (g > 255) g = 255;
      else if (g < 0) g = 0;

      return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
    },
  },
  watch: {
    invalidCredentials(newValue, oldValue) {
      if (newValue && !oldValue) {
        this.openInvalidCredentialsToast();
      }
    },
  },
});
</script>

<style scoped>
.app-brand-name {
  width: 100%;
  text-align: center;
  font-size: 22px;
  font-weight: bold;
}

.login-section-wrapper {
  transform: translateY(10%);
}

.bys-front-logo {
  position: relative;
}

.bys-front-logo ion-img {
  max-width: 150px;
  width: 100%;
  height: auto;
  margin: 0 auto;
}

.bys-front-logo .logo-secondary {
  max-width: 140px;
  transform: translateX(6px);
}

.byline {
  text-transform: capitalize;
  color: #3980ff;
  font-size: 12px;
  letter-spacing: 10px;
  text-align: center;
  margin: 20px 0;
  position: relative;
  top: -10px;
}

.byline::after {
  content: "";
  display: block;
  width: 100%;
  max-width: 138px;
  height: 3px;
  background: #d8d8d8;
  margin: 0 auto;
  position: relative;
  top: -21px;
  left: -6px;
}

.login-text h1 {
  color: black;
  font-size: 22px;
  letter-spacing: 0.5px;
}

ion-grid {
  max-width: 768px;
  margin: 0 auto;
}

.top-image {
  --background: none;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  width: 100%;
  max-width: 768px;
  height: 100%;
  max-height: 300px;
  margin: 0 auto;
}

form ion-item {
  margin-bottom: 20px;
}

form ion-label.sc-ion-label-md-h {
  font-size: 22px;
  margin-bottom: 10px;
}

#container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

#container a {
  text-decoration: none;
}

ion-item {
  --padding-start: 0;
  --padding-end: 0;
}

ion-label {
  font-size: 14px !important; /* Overwrite Ionic label defaults - docs only show color prop avail here? */
  letter-spacing: 0.7px;
}

ion-button[size="large"] {
  height: 45px;
  border-radius: 10px;
}

.association--container {
  text-align: center;
  padding-bottom: 5px;
}

.association--text {
  color: #92949c;
  font-size: 11px;
  font-weight: 600;
  padding: 10px 0;
  text-transform: capitalize;
  margin: 0;
}

.association--images-box {
  display: flex;
  justify-content: space-between;
}

@media all and (max-width: 350px) {
  .association--images-box {
    flex-direction: column;
  }

  .association--image:nth-of-type(2) {
    width: 75%;
  }
}

.association--image {
  flex-basis: 45%;
  margin-bottom: 20px;
}

.forgotten-password {
  color: #3980ff;
  font-size: 16px;
  letter-spacing: 0.7px;
  text-decoration-line: underline;
  cursor: pointer;
  padding-bottom: 5px;
}

ion-input,
ion-label,
ion-item {
  --background: #fafafa;
}

/* ----- Change Autocomplete styles in Chrome ----- */

ion-input :deep(input:-webkit-autofill),
ion-input :deep(input:-webkit-autofill:hover),
ion-input :deep(input:-webkit-autofill:focus) {
  -webkit-box-shadow: 0 0 0px 1000px #fafafa inset;
  -webkit-text-fill-color: black;
  transition: background-color 5000s ease-in-out 0s;
}

/* ----- Change Autocomplete styles in Chrome ----- */
</style>

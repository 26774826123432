<template>

  <div class="radio-container full-width">

    <IonList v-if="!question.multiple">

      <IonRadioGroup
          class="radio-single"
          :name="`group - ${question.type}${question.id}`"
          @ionChange="emitRadioChange(false, $event.target.value)">

        <IonItem
            v-for="option in question.options"
            :key="option.id">
          <IonRadio
              :value="option.value"
              slot="start"
              :name="question.type + question.id"
              mode="md"/>
          <IonLabel>{{ option.label }}</IonLabel>
        </IonItem>

      </IonRadioGroup>

    </IonList>

    <IonList v-else>

      <IonRadioGroup
          class="radio-multiple"
          v-for="option in question.options"
          :key="option.id"
          allow-empty-selection
          :name="`group multiple - ${question.type}${question.id}`"
          @ionChange="emitRadioChange(true, $event.target.value, option.id)">

        <IonItem>
          <IonRadio
              :value="option.value"
              slot="start"
              :name="question.type + question.id"
              mode="md"/>
          <IonLabel>{{ option.label }}</IonLabel>
        </IonItem>

      </IonRadioGroup>

    </IonList>

  </div>

</template>

<script>
import {IonList, IonRadioGroup, IonRadio, IonItem, IonLabel} from "@ionic/vue";

export default {
  name: 'DeveloIonRadio',
  components: {
    IonList,
    IonRadioGroup,
    IonRadio,
    IonItem,
    IonLabel
  },
  props: {
    question: {}
  },
  methods: {
    emitRadioChange(isMultiple, event, option = false) {
      if (!isMultiple) {
        this.$emit('update-radio-input', {inputQuestionId: this.question.id, inputEvent: event})
      } else {
        this.$emit('update-radio-input', {inputQuestionId: this.question.id, inputEvent: event, inputOption: option});
      }
    }
  }
}
</script>
<style scoped>
ion-radio-group.radio-single ion-item:last-of-type {
  margin-bottom: 30px;
}

ion-radio-group.radio-multiple:last-of-type ion-item {
  margin-bottom: 30px;
}

ion-radio {
  width: 26px;
  height: 26px;
}

ion-radio::part(container) {
  border-radius: 50%;
  border: 1px solid #ddd;
}

ion-radio.radio-checked::part(container) {
  border-color: var(--ion-color-primary);
  border-radius: 50%;
}
</style>
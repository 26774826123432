<template>
  <ion-list>
    <ion-item @click="endSurvey" class="item" v-if="isInSurvey">
      <ion-label>End Survey</ion-label>
    </ion-item>

    <ion-item @click="onClickSyncBtn" class="item">
      <ion-label>Sync now</ion-label>
    </ion-item>

    <!--    <ion-item class="item">-->
    <!--      <ion-label>Report a problem</ion-label>-->
    <!--    </ion-item>-->

    <ion-item class="item" @click="() => logout()">
      <ion-label>Log out</ion-label>
    </ion-item>
  </ion-list>
</template>

<script>
import {IonList, IonItem, IonLabel, popoverController, isPlatform} from "@ionic/vue";
import {defineComponent} from "vue";
import {useRouter} from "vue-router";

import SyncingPopover from "../components/SyncingPopover";

export default defineComponent({
  name: "MenuPopover",
  components: {
    IonItem,
    IonLabel,
    IonList,
  },
  data() {
    return {
      popover: false,
    };
  },
  methods: {
    async openPopover(title) {
      this.popover = await popoverController.create({
        component: SyncingPopover,
        cssClass: "develo-ui-success",
        componentProps: {
          title: title,
        },
        mode: (isPlatform("ios") ? "ios" : "md"),
      });
      return this.popover.present();
    },
    async closePopover() {
      if (this.popover) {
        return this.popover.dismiss();
      }
    },
    async fetchNewProjects() {
      // Request new project data
      await this.openPopover("Fetching new projects");

      await this.$http({
        url: process.env.VUE_APP_BACKEND_API_DATA,
        method: "get",
      })
          .then((response) => {
            window.projects = response.data;
          })
          .catch((error) => {
            console.error(error);
          });

      await this.closePopover();
    },
    async onClickSyncBtn() {
      await this.fetchNewProjects();

      // Grab all submissions from LS
      var allSubmissions = [];

      if (localStorage.getItem("submissions") !== null) {
        allSubmissions = JSON.parse(localStorage.getItem("submissions"));

        await this.openPopover("Syncing");

        // Find and update our current survey submission sync state.
        for (let submissionFromLs in allSubmissions) {
          if (allSubmissions[submissionFromLs].synced === false) {
            await this.$http({
              url: process.env.VUE_APP_BACKEND_SUBMIT_SURVEY,
              method: "post",
              data: allSubmissions[submissionFromLs].requestData,
            })
                .then(() => {
                  allSubmissions[submissionFromLs].synced = true;

                  // Emit Sync success so Home can re-render properly

                  localStorage.setItem(
                      "submissions",
                      JSON.stringify(allSubmissions)
                  );

                  this.emitter.emit("sync-success");
                })
                .catch(async (error) => {
                  console.log(error);
                });
          }
        }

        // Finished checking all submissions resume below
        this.closePopover();
      }
    },
    logout() {
      document.getElementsByTagName("ion-backdrop")[0].click();

      localStorage.setItem("token", "");
      localStorage.setItem("user", "");
      this.router.push("/login");
      this.emitter.emit("loadLogin");
    },
    endSurvey() {
      this.$router.go(-1);
      document.getElementsByTagName("ion-backdrop")[0].click();
    },
  },
  computed: {
    isInSurvey() {
      return this.$route.name === "Survey";
    },
  },
  setup() {
    const router = useRouter();
    return {router};
  },
});
</script>

<style scoped>
.item:hover {
  cursor: pointer;
}

ion-item:last-of-type {
  --border-color: transparent;
}
</style>

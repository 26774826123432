<template>
  <div class="range-question-container">
    <div class="range-container">
      <IonSegment
          scrollable="scrollable"
          @ionChange="emitRangeChange($event.target.value)">
        <IonSegmentButton
            :value="i + 1"
            v-for="(range, i) in question.max"
            :key="i">
          <IonLabel class="">
            {{ i + 1 }}
          </IonLabel>
        </IonSegmentButton>
      </IonSegment>
    </div>

    <div class="range-labels">
      <div class="range-start">
        {{ question.label_start }}
      </div>
      <div class="range-end">{{ question.label_end }}</div>
    </div>
  </div>
</template>
<script>
import {IonSegment, IonSegmentButton, IonLabel} from '@ionic/vue';

export default {
  name: 'DeveloIonRange',
  components: {
    IonSegment,
    IonSegmentButton,
    IonLabel
  },
  props: {
    question: {}
  },
  methods: {
    emitRangeChange(event) {
      console.log(this.question.id);
      this.$emit('update-range-input', {inputQuestionId: this.question.id, inputEvent: event});
    }
  }
}
</script>
<style scoped>
.range-question-container {
  width: 100%;
  padding: 0 20px;
}

.range-labels {
  display: flex;
  justify-content: space-between;
  margin: 10px 5px;
  color: #92949c;
  font-size: 13px;
  letter-spacing: 0.38px;
}

.range-container {
  background: #f0f0f0;
  border-radius: 10px;
  width: 100%;
  position: relative;
}

ion-segment {
  padding: 3px 1px;
  --background: #f0f0f0;
}

ion-segment-button {
  min-width: auto;
  --background: #f0f0f0;
  --background-checked: white;
  --background-focused: white;
  --background-hover: white;
  --indicator-color: transparent;
}

ion-segment-button:hover {
  /*background-color: rgba(255, 255, 255, 0.6);*/
  background-color: white;
  border-radius: 10px;
  --border-radius: 10px;
}

ion-segment-button::part(native) {
  border-radius: 10px;
}

ion-segment-button ion-label {
  font-size: 13px !important;
}
</style>
<template>
  <div class="privacy">
    <div class="privacy-content">
      <p>{{ survey.privacy }}</p>

      <IonItem @click="privacyChecked = !privacyChecked">
        <IonCheckbox slot="start"/>
        <ion-label>I agree to the Privacy Policy</ion-label>
      </IonItem>

      <IonButton
          class="proceed-button"
          size="block"
          @click="emitPrivacyConfirmed()"
          :disabled="!privacyChecked"
      >
        Proceed
      </IonButton>
    </div>
  </div>
</template>
<script>
import {IonItem, IonCheckbox, IonLabel, IonButton} from '@ionic/vue';

export default {
  name: 'DeveloSurveyPrivacy',
  components: {
    IonItem,
    IonCheckbox,
    IonLabel,
    IonButton
  },
  props: {
    survey: {},
    introAccepted: Boolean,
  },
  data() {
    return {
      privacyAccepted: false,
      privacyChecked: false,
    }
  },
  methods: {
    emitPrivacyConfirmed() {
      this.$emit('privacy-confirmed')
    }
  }
}
</script>
<style scoped>
.privacy-content {
  max-width: 1000px;
  margin: 0 auto;
  color: #92949c;
  text-align: center;
  letter-spacing: 0.02em;
  min-height: 80vh;
  padding-bottom: 60px;
  position: relative;
}

.proceed-button {
  position: absolute;
  bottom: 0;
  left: 20px;
  right: 20px;
  width: auto;
  padding: 0px;
}

.privacy-content p {
  margin-top: 0;
  padding: 20px;
}

ion-item {
  padding-left: 0;
}

ion-button {
  padding: 0 20px;
  margin-left: 0;
  margin-right: 0;
}

@media all and (max-width: 375px) {
  .privacy-content p {
    padding: 10px 20px;
  }

  ion-item {
    padding-left: 20px;
  }

  ion-button {
    padding: 0 20px;
  }
}

ion-button {
  height: 50px;
  margin-top: 20px;
  cursor: pointer;
}
</style>
<template>
  <ion-page>

    <header-nav/>

    <ion-content>
      <div class="privacy-message">
        <p>Curabitur blandit tempus porttitor. Maecenas sed diam eget risus varius blandit
          sit amet non magna. Maecenas
          faucibus mollis interdum. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Aenean eu leo quam.
          Pellentesque ornare sem lacinia quam venenatis vestibulum. Cras justo odio, dapibus ac facilisis in, egestas
          eget quam.</p>


        <p>Donec ullamcorper nulla non metus auctor fringilla. Donec sed odio dui. Donec sed odio dui. Duis mollis, est
          non
          commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.</p>


        <p>Maecenas faucibus mollis interdum. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh,
          ut
          fermentum massa justo sit amet risus. Donec ullamcorper nulla non metus auctor fringilla. Vestibulum id ligula
          porta felis euismod semper. Donec id elit non mi porta gravida at eget metus. Maecenas faucibus mollis
          interdum.
          Praesent commodo cursus magna, vel scelerisque nisl consectetur et.</p>


        <ion-item>
          <ion-checkbox />
          <ion-label>I agree to the Privacy Policy</ion-label>
        </ion-item>

        <ion-button size="block">Proceed</ion-button>
      </div>



    </ion-content>
  </ion-page>
</template>

<script>
    import {IonPage, IonContent, IonCheckbox, IonLabel, IonButton} from '@ionic/vue';
    import { defineComponent } from 'vue';
    import HeaderNav from '../components/blocks/HeaderNav'

    export default defineComponent({
      name: "Privacy",
      components: {
        HeaderNav,
        IonPage,
        IonContent,
        IonCheckbox,
        IonLabel,
        IonButton
      }
    })
</script>

<style scoped>

  .privacy-message {
    padding: 10px 50px;
    color: #92949C;
    text-align: center;
    letter-spacing: 0.02em;
  }

  ion-item {
    text-align: center;
  }

  @media all and (max-width: 375px) {
    .privacy-message {
      padding: 10px 20px;
    }
  }

  ion-button {
    margin: 20px;
    padding: 0 20px !important;
  }

</style>

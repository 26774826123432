<template>
  <IonSelect
      class="select-option-container full-width"
      :placeholder="question.description ? question.description : 'Select Option'"
      :name="question.type + question.id"
      :multiple="question.multiple"
  >
    <IonSelectOption
        v-for="option in question.options"
        :key="option.id"
        :value="option.value"

    >
      {{ option.label }}
    </IonSelectOption>
  </IonSelect>
</template>
<script>
import {IonSelect, IonSelectOption} from '@ionic/vue';

export default {
  name: 'DeveloIonSelect',
  components: {
    IonSelect,
    IonSelectOption
  },
  props: {
    question: {}
  }
}
</script>
<style scoped>
.select-option-container {
  min-width: 100%;
  margin: 0;
  padding: 0 20px;
}
</style>
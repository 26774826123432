<template>
  <ion-page>
    <header-nav/>
    <ion-content :fullscreen="true" class="content">
      <ion-grid class="ion-no-padding">
        <router-link to="/projects">
          <ion-button class="start-chat-button" size="large" expand="block">
            <h3>Start Survey</h3>
            <ion-icon class="start-chat-icon" :icon="playCircle" size="large"/>
          </ion-button>
        </router-link>

        <ion-row class="ion-padding-horizontal">
          <ion-col>
            <h2>Recent Surveys</h2>
          </ion-col>
        </ion-row>

        <div v-if="!recentSubmissions.length">
          <ion-row class="ion-padding-horizontal">
            <ion-col>
              <p>You have not submitted any recent surveys.</p>
            </ion-col>
          </ion-row>
        </div>

        <div v-else-if="recentSubmissions.length">
          <div
              v-for="(recentSubmission, i) in recentSubmissions"
              :key="i"
              class="survey"
          >
            <div class="survey-content">
              <ion-row class="ion-padding-horizontal">
                <ion-col size-xs="3">
                  <div class="time">
                    {{ recentSubmission.time ? recentSubmission.time : "" }}
                  </div>
                </ion-col>

                <ion-col class="ion-text-end">
                  <div class="date">
                    {{ timeSince(recentSubmission.date, recentSubmission.time) }}
                  </div>
                </ion-col>
              </ion-row>

              <ion-row class="ion-padding-horizontal">
                <ion-col>
                  <div class="title">
                    {{ recentSubmission.surveyTitle }}
                  </div>
                </ion-col>
              </ion-row>

              <ion-row class="ion-padding-horizontal">
                <ion-col>
                  <div class="project">
                    {{ recentSubmission.projectTitle }}
                  </div>
                </ion-col>
              </ion-row>

              <ion-row class="ion-padding-horizontal">
                <ion-col>
                  <div
                      class="is-synced"
                      :class="'sync-' + recentSubmission.synced"
                  >
                    <ion-icon
                        class="sync-icon"
                        :icon="checkmarkCircleOutline"
                    ></ion-icon>
                    <div class="sync-text">
                      {{ recentSubmission.synced ? "SYNCED" : "NOT SYNCED" }}
                    </div>
                  </div>
                </ion-col>
              </ion-row>
            </div>

            <ion-row>
              <div class="survey-break"/>
            </ion-row>
          </div>
        </div>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonPage,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
} from "@ionic/vue";
import {playCircle, checkmarkCircleOutline} from "ionicons/icons";
import {defineComponent} from "vue";
//import {StorageService} from "@/composables/StorageService";

import HeaderNav from "@/components/blocks/HeaderNav";

export default defineComponent({
  name: "Home",
  data() {
    return {
      recentSubmissions: [],
    };
  },
  components: {
    HeaderNav,
    IonContent,
    IonPage,
    IonIcon,
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
  },
  setup() {
    return {
      playCircle,
      checkmarkCircleOutline,
    };
  },
  methods: {
    getRecentSubmissionsFromLs() {
      if (localStorage.getItem("submissions") !== null) {
        return JSON.parse(localStorage.getItem("submissions"));
      }
      return [];
    },


    timeSince(date, time) {
      // Create a date obj from the date str
      // Surveys are saved as enGB Local to LS, below is to correct the mm/dd mismatch

      let day = date.slice(0, 2)
      let month = date.slice(3, 5)
      let year = date.slice(6, 10);
      let hrs = time.slice(0, 2)
      let mins = time.slice(3, 5)

      date = new Date(year, month - 1, day, hrs, mins)

      let seconds = Math.floor((new Date() - date) / 1000);
      let interval = seconds / 31536000;
      let agoMsg = ''

      if (interval > 1) {
        interval < 2 ? agoMsg = 'Year ago' : agoMsg = 'Years ago'
        return Math.floor(interval) + ' ' + agoMsg;
      }
      interval = seconds / 2592000;

      if (interval > 1) {
        interval < 2 ? agoMsg = 'Month ago' : agoMsg = 'Month ago'
        return Math.floor(interval) + ' ' + agoMsg;
      }
      interval = seconds / 86400;

      if (interval > 1) {
        interval < 2 ? agoMsg = 'Day ago' : agoMsg = 'Days ago'
        return Math.floor(interval) + ' ' + agoMsg;
      }
      interval = seconds / 3600;

      if (interval > 1) {
        interval < 2 ? agoMsg = 'Hour ago' : agoMsg = 'Hours ago';
        return Math.floor(interval) + ' ' + agoMsg;
      }
      interval = seconds / 60;

      if (interval > 1) {
        interval < 2 ? agoMsg = 'Minute ago' : agoMsg = 'Minutes ago'
        return Math.floor(interval) + ' ' + agoMsg;
      }

      return Math.floor(seconds) + " seconds ago";
    }
  },
  created() {
    // After survey has been submitted, update our recent submissions from LS
    this.emitter.on("sync-success", () => {
      this.recentSubmissions = this.getRecentSubmissionsFromLs();
    });

    // When comp created, get initial submissions from LS.
    this.recentSubmissions = this.getRecentSubmissionsFromLs();
  },
});
</script>

<style scoped>
.start-chat-button {
  text-transform: capitalize;
  --border-radius: 15px;
  margin: 20px 10px 0 10px;
}

.start-chat-icon {
  margin: 0 10px;
}

h2 {
  font-size: 22px;
}

h3 {
  font-weight: normal;
  margin: 0;
}

.survey .time {
  font-size: 17px;
}

.survey .date,
.survey .project {
  color: #92949c;
}

.survey .project,
.survey .title,
.survey .date {
  font-size: 14px;
}

.survey .sync-text {
  font-size: 12px;
  font-weight: 500;
  transform: translateY(-4px);
  display: inline-block;
  vertical-align: middle;
}

.survey .sync-icon {
  font-size: 17px;
  margin-right: 6px;
  padding: 0;
}

.survey .sync-true {
  color: #55da86;
}

.survey .sync-false {
  color: #ed596d;
}

.survey .survey-break {
  border-bottom: 1px solid var(--ion-border-color);
  width: 100%;
  margin-left: var(--ion-margin);
}

.survey:first-of-type {
  margin-top: var(--ion-margin);
}

.survey {
  margin: calc(var(--ion-margin) / 2) 0;
}

.ion-padding-horizontal {
  padding-left: 10px;
  padding-right: 10px;
}
</style>

<template>
  <div class="text-multiline-container full-width">
    <ion-label position="stacked"></ion-label>
    <ion-item v-for="(line, textLine) in question.lines" :key="textLine">
      <ion-input
          clear-input
          :required="question.required"
          :maxlength="question.max_length"
          @ionChange="emitTextChange($event.target.value, textLine)"
          pattern="text"/>
    </ion-item>
  </div>
</template>
<script>
export default {
  name: 'DeveloIonText',
  props: {
    question: {}
  },
  methods: {
    emitTextChange(event, option = false) {
      this.$emit('update-text-input', {inputQuestionId: this.question.id, inputEvent: event, inputOption: option});
    }
  }
}
</script>
<style scoped>
.question ion-item:last-of-type {
  margin-bottom: 25px; /* min-height - 30 = 24.5 */
}
</style>